
import { css } from "@emotion/react";
import {DIMENSIONS} from '@vc-workspace/utils-dimens';

export const containerStyles = css({
  display: 'flex',
  flexFlow: 'row',
  columnGap: DIMENSIONS.px_52,
  justifyContent: 'space-between',
  '@media screen and (max-width: 768px)': {
    justifyContent: 'space-between',
    columnGap: '0px'
  }
})

export const spinnerContainerStyles = css({
  display: 'inline-block',
  width: '160px',
  marginBottom: DIMENSIONS.px_52,
  '@media screen and (max-width: 768px)': {
    width: '42vw',
    marginBottom: DIMENSIONS.px_52
  }
});