/** @jsxImportSource @emotion/react */

import { useRef, useEffect, useState, useCallback, ReactElement } from "react";
import Skeleton from '@mui/material/Skeleton';

import { containerStyles, spinnerContainerStyles } from './utils-infinite-scroll.module.style';

export interface UtilsInfiniteScrollProps {
  currPage: number,
  callback: (newPage: number) => void,
  gridCount?: number,
  disableDefaultGutter?: boolean,
  customIncrementBy?: number,
  customLoaderLayout?: ReactElement
}

export function UtilsInfiniteScroll(props: UtilsInfiniteScrollProps) {
  const loaderRef = useRef(null);

  const loadMore = useCallback((entries) => {
    const first = entries[0];
    if (first.isIntersecting) {
      const incrementBy = props.customIncrementBy || 1;
      props.callback(props.currPage + incrementBy);
    }
  }, [props])

  useEffect(() => {
    const observer = new IntersectionObserver(loadMore, {rootMargin: "0px 0px 300px 0px"})

    const observingEle = loaderRef.current
    const currObserver = observer

    if(observingEle) {
      currObserver.observe(observingEle)
    }

    return () => {
      if(observingEle) {
        currObserver.unobserve(observingEle)
      }
    }
  }, [props]);

  return (
    <div ref={loaderRef}>
      {
        props.customLoaderLayout ? props.customLoaderLayout : (
          <div  className={`${!props.disableDefaultGutter ? "page-cushion-container" : ""} mt-12`}>
            <div css={containerStyles}>
              {
                [...Array(props.gridCount || 4)].map((i) => (
                  <div css={spinnerContainerStyles}>
                    <Skeleton animation="wave" variant="rectangular" height={200} />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" width="60%" />
                    <Skeleton animation="wave" width="40%" height={40} />
                    <Skeleton animation="wave" width="70%" />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
