import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

export interface UtilsIntersectionObserverProps {
  children: ReactElement,
  intersectionCallback: () => void,
  intersectionVerticalThreshold?: string,
  triggerOnEachInteraction?: boolean
}

export function UtilsIntersectionObserver(props: UtilsIntersectionObserverProps) {

  const [hasTriggeredInteraction, setHasTriggeredInteraction] = useState(false);

  const containerRef = useRef(null);

  const onIntersection = useCallback((entries) => {
    const first = entries[0];
    if (first.isIntersecting) {

      if(!hasTriggeredInteraction || (hasTriggeredInteraction && props.triggerOnEachInteraction)) {
        props.intersectionCallback();
      }

      setHasTriggeredInteraction(true);

    }
  }, [props, hasTriggeredInteraction])

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {rootMargin: `0px 0px ${props.intersectionVerticalThreshold || '0px'} 0px`})

    const observingEle = containerRef.current
    const currObserver = observer

    if(observingEle) {
      currObserver.observe(observingEle)
    }

    return () => {
      if(observingEle) {
        currObserver.unobserve(observingEle)
      }
    }
  }, [props, hasTriggeredInteraction]);

  return (
    <div ref={containerRef}>
      {props.children}
    </div>
  );
}

export default UtilsIntersectionObserver;
