import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { baseContainerStyles, baseContainerWrapperStyles, productImageStyles } from "./custom-toast.module.style";
import { Typography } from "@vc-workspace/utils-typography";
import { TailwindColors } from "@vc-workspace/utils-colors";
import { getDisplayImageObj } from "@vc-workspace/utils-helpers";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppConfigContext } from "../global-context-wrapper/global-context-wrapper";
import { useContext } from "react";
/*******************************************************************
            Toast triggered when guest adds item to wishlist
*******************************************************************/

export var GuestAddToWishlistToast = function GuestAddToWishlistToast(_ref) {
  var sia = _ref.sia,
      context = _ref.context;

  var _useContext = useContext(AppConfigContext),
      toggleLoginModalVisibility = _useContext.toggleLoginModalVisibility;

  var displayImage = getDisplayImageObj(sia.itemDetail.images[0], "1");

  var onClick = function onClick() {
    toggleLoginModalVisibility(true);
  };

  return _jsx("div", {
    css: baseContainerWrapperStyles,
    children: _jsxs("div", {
      css: baseContainerStyles,
      className: "flex items-center justify-between",
      onClick: onClick,
      children: [_jsxs("div", {
        className: "flex items-center",
        children: [_jsxs("picture", {
          children: [_jsx("source", {
            srcSet: displayImage.webP,
            type: "image/webp"
          }), _jsx("img", {
            src: displayImage.url,
            css: productImageStyles,
            alt: sia.itemDetail.name
          })]
        }), _jsx(Typography, {
          type: "Body1",
          color: TailwindColors.grey[900],
          className: "pl-3",
          semi_bold: true,
          children: "Added to Wishlist"
        })]
      }), _jsxs(Typography, {
        type: "Body1",
        color: TailwindColors.purple[800],
        semi_bold: true,
        children: ["Login to save ", _jsx(ChevronRightIcon, {
          fontSize: "small"
        })]
      })]
    })
  });
};
/*******************************************************************
            Toast triggered when user adds item to wishlist
*******************************************************************/

export var UserAddToWishlistToast = function UserAddToWishlistToast(_ref2) {
  var sia = _ref2.sia;
  var displayImage = getDisplayImageObj(sia.itemDetail.images[0], "1");

  var onClick = function onClick() {
    window.open('/profile/wishlist');
  };

  return _jsx("div", {
    css: baseContainerWrapperStyles,
    children: _jsxs("div", {
      css: baseContainerStyles,
      className: "flex items-center justify-between",
      onClick: onClick,
      children: [_jsxs("div", {
        className: "flex items-center",
        children: [_jsxs("picture", {
          children: [_jsx("source", {
            srcSet: displayImage.webP,
            type: "image/webp"
          }), _jsx("img", {
            src: displayImage.url,
            css: productImageStyles,
            alt: sia.itemDetail.name
          })]
        }), _jsx(Typography, {
          type: "Body1",
          color: TailwindColors.grey[900],
          className: "pl-3",
          semi_bold: true,
          children: "Added to Wishlist"
        })]
      }), _jsxs(Typography, {
        type: "Body1",
        color: TailwindColors.grey[600],
        semi_bold: true,
        children: ["View ", _jsx(ChevronRightIcon, {
          fontSize: "small"
        })]
      })]
    })
  });
};
/*******************************************************************
            Toast triggered when anyone adds item to cart
*******************************************************************/

export var AddToBag = function AddToBag(_ref3) {
  var sia = _ref3.sia;
  var displayImage = getDisplayImageObj(sia.itemDetail.images[0], "1");

  var onClick = function onClick() {
    window.open('/cart/init');
  };

  return _jsx("div", {
    css: baseContainerWrapperStyles,
    children: _jsx("div", {
      css: baseContainerStyles,
      className: "flex items-center justify-between",
      onClick: onClick,
      children: _jsxs("div", {
        className: "flex items-center",
        children: [_jsxs("picture", {
          children: [_jsx("source", {
            srcSet: displayImage.webP,
            type: "image/webp"
          }), _jsx("img", {
            src: displayImage.url,
            css: productImageStyles,
            alt: sia.itemDetail.name
          })]
        }), _jsx(Typography, {
          type: "Body1",
          color: TailwindColors.grey[900],
          className: "pl-3",
          semi_bold: true,
          children: "Added to Bag"
        })]
      })
    })
  });
};